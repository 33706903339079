
import { Component, Vue } from 'vue-property-decorator'
import ThePolicy from '@/components/policy/ThePolicy.vue'

@Component({
  components: {
    ThePolicy
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class PolicyPage extends Vue {

}
